.user-view-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--whitePink);
  background-color: var(--darkPurple);
}

.user-container {
  width: 500px;
  height: 350px;
  background-color: var(--maroon);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 40px;
  margin-top: 40px;
  color: var(--whitePink);
  font-size: 1.8rem;
}

.user-container input {
  width: 60%;
  height: 60px;
  font-size: 1.8rem;
  padding: 20px;
  background-color: var(--darkPurple);
  border: none;
  border-radius: 10px 0 0 10px;
  color: var(--whitePink);
}

.user-container button {
  width: 20%;
  height: 60px;
  padding: 12px;
  background-color: #262629;
  border: none;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.user-container button:hover {
  filter: brightness(85%);
}

.user-container button:active {
  filter: brightness(65%);
}

.user-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  height: 40px;
}

/* element.style {
  color: var(--whitePink);
} */
