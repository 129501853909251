.start-view-container {
  height: 100vh;
}

.start-view-items-container {
  height: 86%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--darkPurple);
}

#start-view-options-container {
  width: 500px;
  height: 350px;
  background-color: var(--maroon);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 40px;
}

#start-view-options-container > button {
  display: block;
  width: 320px;
  height: 70px;
  background-color: var(--darkPurple);
  border: solid;
  border-color: #d10643;
  border-radius: 15px;
  box-shadow: inset 0 0 5px 1px var(--hotPink), 0 0 7px 2px var(--hotPink);
  font-family: 'Tourney', cursive;
  font-weight: 900;
  font-size: 1.8rem;
  color: var(--whitePink);
  transition: all 100ms ease-in-out;
}

#start-view-options-container > button:hover {
  border-color: #e10748;
  border-width: 3px;
}

#start-view-options-container > button:active {
  border-color: #e10748;
  border-width: 3px;
  transform: translateY(5px);
}
