.users-container {
  color: var(--whitePink);
  font-size: 1.4rem;
  width: 880px;
  background-color: #2f2f33;
  height: fit-content;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.users-container button {
  margin-left: 20px;
  font-size: 1.2rem;
  padding: 5px 8px;
  border: solid;
  color: var(--whitePink);
  background-color: var(--maroon);
  border-color: var(--maroon);
  border-radius: 8px;
  border-width: 3px;
  transition: all 150ms ease-in-out;
}

.users-container button:hover {
  border-color: #932d42;
}
