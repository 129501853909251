.navbar-container {
  width: 100%;
  height: 75px;
  background-color: #311d23;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
}

.navbar-logo {
  width: 170px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #311d23;
  color: var(--whitePink);
  font-family: 'Tourney', cursive;
  font-size: 1.7rem;
  font-weight: 300;
  font-style: italic;
  border-color: #6f2232;
  border-style: solid;
  box-shadow: inset 0 0 40px 40px #6f2232, 0 0 0 0 #6f2232;
  transition: all 150ms ease-in-out;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container .user {
  margin-left: 30px;
  font-size: 1.3rem;
  color: var(--whitePink);
}

.navbar-logo:hover {
  box-shadow: inset 0 0 10px 0 #6f2232, 0 0 12px 5px #6f2232;
  border-color: #9c2d44;
}

.navbar-logo:active {
  transform: translateY(5px);
}

.navbar-buttons-container button {
  width: fit-content;
  padding: 0 18px;
  height: 50px;
  border-radius: 10px;
  margin-left: 10px;
  background-color: #311d23;
  color: var(--whitePink);
  font-family: 'Tourney', cursive;
  font-size: 1.4rem;
  border-color: #6f2232;
  border-style: solid;

  box-shadow: inset 0 0 40px 40px #6f2232, 0 0 0 0 #6f2232;
  transition: all 150ms ease-in-out;
}

.navbar-buttons-container button:hover {
  box-shadow: inset 0 0 10px 0 #6f2232, 0 0 12px 5px #6f2232;
  border-color: #9c2d44;
}

.navbar-buttons-container button:active {
  transform: translateY(5px);
}
