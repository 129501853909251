.board-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rank-board-container {
  width: 90%;
  height: 700px;
  background-color: teal;
  margin: 60px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board-view-board-info-container {
  width: 880px;
  height: 50px;
  color: var(--whitePink);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  margin-top: 40px;
}

.board-view-board-code {
  background-color: var(--maroon);
  border-radius: 10px;
  padding: 12px 18px;
}
