/* ALL TIERS */
.all-tiers-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0 30px;
}

.all-tiers-container h2 {
  color: var(--whitePink);
}

/* TIER */
.tier-container {
  display: flex;
  align-items: stretch;
  margin-bottom: 8px;
  width: 880px;
  height: auto;
}

#Select-tier {
  margin-top: 20px;
  width: 100%;
}
#Select-tier .draggable-items {
  width: 880px;
}

.tier-name-container-S {
  background-color: #ff6663;
  height: auto;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tier-name-container-S h2 {
  color: var(--darkPurple);
}

.tier-name-container-A {
  background-color: #feb144;
  min-height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tier-name-container-A h2 {
  color: var(--darkPurple);
}

.tier-name-container-B {
  background-color: #fdfd97;
  min-height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tier-name-container-B h2 {
  color: var(--darkPurple);
}

.tier-name-container-C {
  background-color: #9ee09e;
  min-height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tier-name-container-C h2 {
  color: var(--darkPurple);
}

.tier-name-container-D {
  background-color: #9ec1cf;
  min-height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tier-name-container-D h2 {
  color: var(--darkPurple);
}

.tier-name-container-F {
  background-color: #cc99c9;
  min-height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tier-name-container-F h2 {
  color: var(--darkPurple);
}

.tier-name-container-Select {
  display: none;
}

/* ITEMS */
.draggable-items {
  width: 800px;
  min-height: 80px;
  display: flex;
  flex-wrap: wrap;
}

.items {
  height: 80px;
  width: 80px;
}
