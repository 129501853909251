.join-board-screen-view-container {
  height: 100vh;
}

.join-board-screen-view-input-container {
  height: 86%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-board-screen-input-container {
  width: 500px;
  height: 350px;
  padding: 36px;
  background-color: var(--maroon);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-family: 'Tourney', cursive;
  font-weight: 900;
  font-size: 1.8rem;
  color: var(--whitePink);
}

.join-board-screen-input-container > div {
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-board-screen-input-container > div > input {
  width: 60%;
  height: 60px;
  font-size: 1.8rem;
  padding: 20px;
  background-color: var(--darkPurple);
  border: none;
  border-radius: 10px 0 0 10px;
  color: var(--whitePink);
}

.join-board-screen-input-container > div > .button {
  width: 20%;
  height: 60px;
  padding: 12px;
  background-color: #262629;
  border: none;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-board-screen-input-container > div > .button img {
  width: 40px;
  height: 40px;
}

.join-board-screen-input-container > h3 {
  margin: 0 0 16px 0;
  color: var(--whitePink);
}

input:focus {
  outline: none;
  border: none;
  background-color: var(--darkPurple);
  color: var(--whitePink);
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--darkPurple);
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--whitePink);
}

.button:hover {
  filter: brightness(85%);
}

.button:active {
  filter: brightness(65%);
}
