@import url('https://fonts.googleapis.com/css2?family=Tourney:ital,wght@0,900;1,100;1,300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tourney', cursive;
}

:root {
  --darkPurple: #1a1a1d;
  --grey: #4e4e50;
  --maroon: #6f2232;
  --darkPink: #950740;
  --hotPink: #c3073f;
  --whitePink: #ffecec;
  --darkMaroon: #820000;
}

body {
  background-color: var(--darkPurple);
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
}
